import GenericError from "shared/errors/GenericError";
import {
  IQueryParameters,
  TOfferListQueryParameters,
} from "shared/types/assetBuilder";
import { host, returnUrlWithParameters } from "./utils/helpers";

import { TSortByOption } from "screens/assetBuilder/offers/select/OfferFilterV2";
import { GetAudienceIntegrationLogsParameters } from "screens/campaignManagement/audienceManager/types";
import {
  IGetFacebookIEParams,
  IGetSharedAdReviewParams,
} from "shared/types/adReview";
import { UpdatePlannerInstanceAdParams } from "shared/types/campaignPlanner";
import {
  IGetResizedImgQueryParams,
  IGetS3ObjectVersionsQueryParams,
  IGetVersionOfJsonFromS3QueryParams,
  OfferType,
} from "shared/types/shared";
import {
  APIType,
  Environment,
  IConfig,
  environmentList as preDefinedEnvList,
} from "./shared/types/configuration";

const {
  REACT_APP_DOMAIN,
  REACT_APP_AV2_ENABLE_VIDEO,
  REACT_APP_AV2_DEALERSCIENCE_ENABLED,
  REACT_APP_AV2_ASSETLAUNCHER_ENABLED,
  REACT_APP_BROCHURE_DOMAIN,
} = process.env;

const enableVideoSupport = REACT_APP_AV2_ENABLE_VIDEO
  ? REACT_APP_AV2_ENABLE_VIDEO === "true"
  : false;

const enablePaymentEngine = REACT_APP_AV2_DEALERSCIENCE_ENABLED === "true";

const enableLauncher = REACT_APP_AV2_ASSETLAUNCHER_ENABLED
  ? REACT_APP_AV2_ASSETLAUNCHER_ENABLED === "true"
  : false;

const preDefinedUrls = {
  av2: {
    local: "http://localhost",
    dev: `${REACT_APP_DOMAIN}`,
    stg: `${REACT_APP_DOMAIN}`,
    prd: `${REACT_APP_DOMAIN}`,
  },
  brochure: {
    local: "http://localhost",
    dev: `${REACT_APP_BROCHURE_DOMAIN}`,
    stg: `${REACT_APP_BROCHURE_DOMAIN}`,
    prd: `${REACT_APP_BROCHURE_DOMAIN}`,
  },
};

const configGenerator = (
  env: Environment = "local",
  environmentList: Environment[] = preDefinedEnvList,
  urls: Record<APIType, Record<Environment, string>> = preDefinedUrls,
): IConfig => {
  const h = host(env, urls);
  // check if the env parameter is in the environmentList.
  // user can always pass wrong env as configGenerator('wrong' as Environment), so this check is needed.
  if (!environmentList.includes(env)) {
    throw new GenericError({
      message: `Assigning hosts in configGenerator but env: ${env} is not valid.`,
    });
  }

  // check if keys in urls are in the environmentList
  Object.keys(urls).forEach(key => {
    Object.keys(urls[key as APIType]).forEach(envKey => {
      if (!environmentList.includes(envKey as Environment)) {
        throw new GenericError({
          message: `${envKey} in the url list is not in the environment list: ${environmentList}`,
        });
      }
    });
  });

  const apiVersions = {
    local: "v1",
    dev: "v1",
    stg: "v1",
    prd: "v1",
  };

  const apiVersion = apiVersions[env] as string;

  // templates, stamps, neworders need 5002

  const enableWorkfront = process.env.REACT_APP_WORKFRONT_ENABLED
    ? process.env.REACT_APP_WORKFRONT_ENABLED === "true"
    : false;

  const enableEvoxJellybeans = process.env.REACT_APP_EVOX_ENABLED === "true";

  // define all config below
  const config: IConfig = {
    env,
    featureFlags: {
      supportVideos: enableVideoSupport,
      enablePaymentEngine,
      enableWorkfront,
      enableLauncher,
      enableEvoxJellybeans,
    },
    services: {
      designStudio: {
        stampBaseUrl: `${h("offer")}/${apiVersion}/stamps`,
        createTagUrl: `${h("core")}/${apiVersion}/tags`,
        getTagsUrl: `${h("core")}/${apiVersion}/tags`,
        createTemplateUrl: `${h("offer")}/${apiVersion}/templates`,
        updateTemplateUrl: `${h("offer")}/${apiVersion}/templates`,
        deleteTemplateUrl: `${h("offer")}/${apiVersion}/templates`,
        getTemplatesUrl: parameters =>
          returnUrlWithParameters({
            baseUrl: `${h("offer")}/${apiVersion}`,
            endpoint: parameters?.hasSizeFilter
              ? "templatesByArtboardSize"
              : "templates",
            parameters,
          }),
        createArtboardUrl: `${h("core")}/${apiVersion}/artboards`,
        getArtboardsUrl: `${h("core")}/${apiVersion}/artboards`,
        getStampsUrl: `${h("offer")}/${apiVersion}/stamps`,
        createStampUrl: `${h("offer")}/${apiVersion}/stamps`,
        publishStampUrl: `${h("offer")}/${apiVersion}/stamps/publish`,
        saveDraftUrl: `${h("offer")}/${apiVersion}/templates/canvas`,
        getStockMediaUrl: `${h("offer")}/${apiVersion}/get-media`,
        trackStockMediaDownloadUrl: `${h(
          "offer",
        )}/${apiVersion}/track-media-download`,
        publishStamp: `${h("offer")}/${apiVersion}/stamps`,
        duplicateStampUrl: `${h("offer")}/${apiVersion}/stamps/duplicate`,
        uploadThemeBackgroundImageUrl: `${h(
          "offer",
        )}/${apiVersion}/artboards/background-theme-image`,
        getTemplateVersions: `${h(
          "offer",
        )}/${apiVersion}/templates/template-versions`,
        getHtmlSignedUrl: `${h(
          "offer",
        )}/${apiVersion}/templates/getHtmlSignedUrl`,
        getZipSignedUrl: `${h(
          "offer",
        )}/${apiVersion}/templates/getZipSignedUrl`,
        deleteZipFileUrl: `${h("offer")}/${apiVersion}/templates/deleteZipFile`,
        validateZipFileUrl: `${h(
          "offer",
        )}/${apiVersion}/templates/validateZipFile`,
        createFilterPresetUrl: `${h("offer")}/${apiVersion}/presets/create`,
        fetchFilterPresetsUrl: feature =>
          `${h("offer")}/${apiVersion}/presets/feature/${feature}/list`,
        deleteFilterPresetUrl: ({ id, feature }) =>
          `${h("offer")}/${apiVersion}/presets/${id}/feature/${feature}/remove`,
      },
      exportToImageAndVideo: `${h("offer")}/${apiVersion}/image-video-export`,
      getExportState: `${h("assetgenerator")}/${apiVersion}/export-job-status`,
      getExportZipUrl: `${h(
        "assetgenerator",
      )}/${apiVersion}/get-export-zip-url`,
      newOrder: {
        newOrders: `${h("assetbuilder")}/${apiVersion}/neworders`,
      },

      workFrontUrl: `${h("offer")}/${apiVersion}/workfront`,

      wfTemplateUrl: `${h("offer")}/${apiVersion}/workfront/templates`,

      createUserUrl: `${h("core")}/${apiVersion}/auth/users`,
      deleteUserUrl: `${h("core")}/${apiVersion}/auth/users`,
      updateUserUrl: `${h("core")}/${apiVersion}/auth/users`,
      getUsersUrl: `${h("core")}/${apiVersion}/auth/users`,
      updateUserStatusUrl: `${h(
        "core",
      )}/${apiVersion}/auth/users/{userId}/status`,
      resetUserPasswordUrl: `${h(
        "core",
      )}/${apiVersion}/auth/reset-user-password`,
      createOemUrl: `${h("core")}/${apiVersion}/oems`,
      getOemsUrl: `${h("core")}/${apiVersion}/oems`,
      getOemsByNameUrl: `${h("core")}/${apiVersion}/oems-by-name`,
      getOemUrl: `${h("core")}/${apiVersion}/oem`,
      createTagUrl: `${h("core")}/${apiVersion}/order-tags`,
      getTagsUrl: `${h("core")}/${apiVersion}/order-tags`,
      updateOemUrl: `${h("core")}/${apiVersion}/oems`,
      deleteOemUrl: `${h("core")}/${apiVersion}/oems`,
      deleteBrandsAccountsUrl: `${h(
        "core",
      )}/${apiVersion}/delete-brands-accounts`,
      getSpecialCampaignsUrl: `${h("core")}/${apiVersion}/special-campaigns`,
      createDealerUrl: `${h("core")}/${apiVersion}/dealers`,
      deleteDealerUrl: `${h("core")}/${apiVersion}/dealers`,
      getDealersUrl: `${h("core")}/${apiVersion}/dealers`,
      getDealerUrl: `${h("core")}/${apiVersion}/dealer`,
      getDealerByNamesUrl: `${h("core")}/${apiVersion}/dealer-by-names`,
      updateDealerUrl: `${h("core")}/${apiVersion}/dealers`,
      getDashboardUrl: `${h("core")}/${apiVersion}/dashboard`,
      getDashboardAvailableModelsUrl: `${h(
        "core",
      )}/${apiVersion}/dashboards/models`,
      getDashboardsUrl: `${h("core")}/${apiVersion}/dashboards`,
      getPublicDashboardUrl: `${h("core")}/${apiVersion}/public-dashboard`,
      getPublicDashboardsUrl: `${h("core")}/${apiVersion}/public-dashboards`,
      createOrUpdatePublicDashboardUrl: `${h(
        "core",
      )}/${apiVersion}/public-dashboard`,
      validatePasswordUrl: `${h(
        "core",
      )}/${apiVersion}/public-dashboard/validate`,
      deletePublicDashboardUrl: `${h("core")}/${apiVersion}/public-dashboard`,
      deletePublicDashboardsUrl: `${h("core")}/${apiVersion}/public-dashboards`,
      createOrUpdateDashboardUrl: `${h("core")}/${apiVersion}/dashboards`,
      deleteDashboardUrl: `${h("core")}/${apiVersion}/dashboards`,
      getDashboardSettingsUrl: `${h("core")}/${apiVersion}/dashboard-settings`,
      getAccountsUrl: `${h("core")}/${apiVersion}/accounts`,
      uploadImagesUrl: `${h("core")}/${apiVersion}/upload/images`,
      uploadFont: `${h("core")}/${apiVersion}/upload/font`,
      generateThumbnailUrl: `${h("adlibrary")}/${apiVersion}/thumbnail`,
      getSignedPutUrlUrl: `${h("core")}/${apiVersion}/upload/signed`,
      postClientErrorUrl: `${h("core")}/${apiVersion}/log-client-error`,
      getLookerThumbnailUrl: `${h("metrics")}/${apiVersion}/metrics/thumbnail`,
      generatePublicLookerIframeUrl: `${h(
        "metrics",
      )}/${apiVersion}/metrics/iframe/public`,
      generateLookerIframeUrl: `${h("metrics")}/${apiVersion}/metrics/iframe`,
      getNotificationsUrl: `${h("notification")}/${apiVersion}/notifications`,
      createOrUpdateNotificationUrl: `${h(
        "notification",
      )}/${apiVersion}/notification`,
      batchCreateNotificationsUrl: `${h(
        "notification",
      )}/${apiVersion}/batch/notifications`,
      batchUpdateNotificationsUrl: `${h(
        "notification",
      )}/${apiVersion}/batch/notifications`,
      getNotificationSettingsUrl: `${h(
        "notification",
      )}/${apiVersion}/notification/settings`,
      updateNotificationSettingsUrl: `${h(
        "notification",
      )}/${apiVersion}/notification/settings`,

      legal: {
        addOrDeleteOemToDynamoDbUrl: `${h(
          "legal",
        )}/${apiVersion}/oem-management-legal-lingos`,

        getOemTablesLegalLingosUrl: `${h(
          "legal",
        )}/${apiVersion}/all-oem-legal-lingos`,
        updateOemTablesLegalLingosUrl: `${h(
          "legal",
        )}/${apiVersion}/all-oem-legal-lingos`,

        getStateTablesLegalLingosUrl: `${h(
          "legal",
        )}/${apiVersion}/all-state-legal-lingos`,
        updateStateTablesLegalLingosUrl: `${h(
          "legal",
        )}/${apiVersion}/all-state-legal-lingos`,

        createStateDisclosureUrl: `${h("legal")}/${apiVersion}/disclosures`,
        getStateDisclosuresUrl: `${h("legal")}/${apiVersion}/disclosures`,

        createStateExceptionUrl: `${h("legal")}/${apiVersion}/exceptions`,
        getStateExceptionsUrl: `${h("legal")}/${apiVersion}/exceptions`,

        getMessagesandExceptionsUrl: `${h(
          "legal",
        )}/${apiVersion}/messages-and-exceptions`,
        saveMessagesandExceptionsUrl: `${h(
          "legal",
        )}/${apiVersion}/messages-and-exceptions`,
        getMessageUrl: `${h("legal")}/${apiVersion}/legal-lingo/messaging`,
        getDisclosuresUrl: `${h("legal")}/${apiVersion}/legal-disclosures`,

        getDisclosureUrl: parameters =>
          `${h("legal")}/${apiVersion}/legal-disclosure/${parameters}`,
        createDisclosureUrl: `${h("legal")}/${apiVersion}/legal-disclosure`,
        updateDisclosureUrl: `${h("legal")}/${apiVersion}/legal-disclosure`,
      },

      getRawOfferByVinUrl: (vin: string) => {
        const queryString = `vin=${vin}`;
        return `${h(
          "assetbuilder",
        )}/${apiVersion}/raw-offer-data?${queryString}`;
      },

      fetchSelectedOfferListUrl: (selectedOfferVins: string[]) => {
        const queryString = `selectedOfferVins=${selectedOfferVins.join()}`;

        return `${h(
          "offer",
        )}/${apiVersion}/asset-builder/selected-offer-list?${queryString}`
          .split("|")
          .join("%7C");
      },

      fetchOfferListUrl: (parameters: IQueryParameters) => {
        /* [AV2-2846-2] Disable checkedOfferFilters temporarily
           const queryString = Object.keys(parameters).map(key => {
             return key === "sortByFilter" || key === "checkedOfferFilters"
               ? `${key}=${encodeURIComponent(JSON.stringify(parameters[key]))}`
               : `${key}=${parameters[key]}`;
           });
         */
        const queryString = Object.keys(parameters).map(key => {
          return key === "sortByFilter"
            ? `${key}=${encodeURIComponent(JSON.stringify(parameters[key]))}`
            : key === "checkedOfferFilters"
            ? `${key}=${encodeURIComponent("[]")}`
            : `${key}=${parameters[key]}`;
        });

        return `${h(
          "assetbuilder",
        )}/${apiVersion}/asset-builder/offer-list?${queryString.join("&")}`
          .split("|")
          .join("%7C");
      },

      // Task on https://theconstellationagency.atlassian.net/browse/AV2-3675
      fetchOfferListUrlV2: (parameters: TOfferListQueryParameters) => {
        const searchParams = new URLSearchParams();
        if (parameters.vehicleCondition)
          searchParams.append(
            "vehicleCondition",
            parameters.vehicleCondition as string,
          );

        if (parameters.offerTypes) {
          searchParams.append(
            "offerTypes",
            (parameters.offerTypes as OfferType[]).join(","),
          );
        }

        if (parameters.sortBy) {
          searchParams.append(
            "sortBy",
            (parameters.sortBy as TSortByOption[])
              .map(sortByOption => `${sortByOption.key}-${sortByOption.order}`)
              .join(","),
          );
        }

        if (parameters.vins) {
          searchParams.append("vins", (parameters.vins as string[]).join(","));
        }

        if (parameters.searchBy) {
          searchParams.append("searchBy", parameters.searchBy as string);
        }

        if (parameters.feedId) {
          searchParams.append(
            "feedId",
            encodeURIComponent(parameters.feedId as string),
          );
        }
        if (parameters.filterField) {
          searchParams.append("filterField", parameters.filterField as string);
        }

        searchParams.append(
          "currentPage",
          `${(parameters.currentPage as number) || 1}`,
        );
        searchParams.append(
          "dealerCode",
          (parameters.dealerCode as string) || "",
        );

        return `${h(
          "assetbuilder",
        )}/${apiVersion}/asset-builder/offer-list-v2?${searchParams.toString()}`;
      },

      offerEditUrl: `${h("offer")}/${apiVersion}/asset-builder/edit-offer`,
      fetchStampUrl: `${h("offer")}/${apiVersion}/stamp`,
      generateCanvasUrl: `${h("offer")}/${apiVersion}/build-asset-review`,
      uploadCanvasImageUrl: `${h("offer")}/${apiVersion}/upload-canvas-image`,

      assetBuilder: {
        offerEdits: `${h("assetbuilder")}/${apiVersion}/asset-builder/edits`,
        getOfferEditsUrl: (vin: string) => {
          return `${h(
            "assetbuilder",
          )}/${apiVersion}/asset-builder/offer-list-v2/edits/${vin}`;
        },
        orderStateUrl: `${h("offer")}/${apiVersion}/order-state`,
        feedOrderUrl: `${h("offer")}/${apiVersion}/feed-order`,
        ordersUrl: `${h("offer")}/${apiVersion}/orders`,
        tagsUrl: `${h("assetbuilder")}/${apiVersion}/order/tags`,
        duplicateOrderState: `${h(
          "offer",
        )}/${apiVersion}/duplicate-order-state`,
        pushToProof: `${h("assetbuilder")}/${apiVersion}/push-proof`,
        proofExportData: (parameters: IQueryParameters) => {
          const queryString = Object.keys(parameters).map(
            key => `${key}=${parameters[key]}`,
          );

          return `${h(
            "offer",
          )}/${apiVersion}/proofExportData/?${queryString.join("&")}`;
        },
        generatePDF: `${h(
          "assetbuilder",
        )}/${apiVersion}/asset-builder/generate-pdf`,
        generatePDFInvoker: `${h(
          "assetbuilder",
        )}/${apiVersion}/asset-builder/generate-pdf-invoker`,
        feedDataToPDF: `${h(
          "assetbuilder",
        )}/${apiVersion}/asset-builder/generate-feedData-pdf`,
        coopSubmission: `${h(
          "assetbuilder",
        )}/${apiVersion}/coop-integration/coopSubmission`,
        feedDataToCSV: `${h("offer")}/${apiVersion}/feed-csv`,
        getWorkfrontFolders: `${h("offer")}/${apiVersion}/wfFolders`,
        getPaymentEngineData: `${h(
          "offer",
        )}/${apiVersion}/asset-builder/dealer-science`,
        getTransactabilityScores: `${h(
          "offer",
        )}/${apiVersion}/asset-builder/transactability-scores`,
        getJellybeanImages: `${h(
          "assetbuilder",
        )}/${apiVersion}/asset-builder/jellybean-images`,
        uploadLifestyleImageUrl: `${h(
          "assetbuilder",
        )}/${apiVersion}/asset-builder/lifestyle-image`,
        uploadCustomImageUrl: `${h(
          "assetbuilder",
        )}/${apiVersion}/asset-builder/custom-image`,

        fetchThemeBackgroundImageUrl: `${h(
          "offer",
        )}/${apiVersion}/artboards/get-background-theme-image`,
        exportForBatchToS3Url: `${h(
          "assetbuilder",
        )}/${apiVersion}/asset-builder/batch-export`,
        updateWebIntUrl: `${h(
          "webintegration",
        )}/${apiVersion}/web-integration/update-web-integration`,
        createWebIntegrationUrl: `${h(
          "webintegration",
        )}/${apiVersion}/web-integration/web-integrations`,
        getWebIntegrationsUrl: (parameters: IQueryParameters) => {
          const queryString = Object.keys(parameters).map(
            key => `${key}=${parameters[key]}`,
          );

          return `${h(
            "webintegration",
          )}/${apiVersion}/web-integration/web-integrations?${queryString.join(
            "&",
          )}`;
        },
        getWebIntegrationCodeUrl: (parameters: IQueryParameters) => {
          const queryString = Object.keys(parameters).map(
            key => `${key}=${parameters[key]}`,
          );

          return `${h(
            "webintegration",
          )}/${apiVersion}/web-integration/web-integration-code?${queryString.join(
            "&",
          )}`;
        },
        getWebIntegrationStatusUrl: (parameters: IQueryParameters) => {
          const queryString = Object.keys(parameters).map(
            key => `${key}=${parameters[key]}`,
          );

          return `${h(
            "webintegration",
          )}/${apiVersion}/web-integration/web-integration-status?${queryString.join(
            "&",
          )}`;
        },
        exportAsHtml: `${h(
          "assetbuilder",
        )}/${apiVersion}/asset-builder/export-as-html`,

        getUpdateSingleFieldUrl: (vin: string) => {
          return `${h(
            "assetbuilder",
          )}/${apiVersion}/asset-builder/offers/${vin}`;
        },
        getFlaggedVins: `${h(
          "assetbuilder",
        )}/${apiVersion}/asset-builder/offers/flagged`,
      },

      uploadAssetToWF: `${h("offer")}/${apiVersion}/upload-asset-wf`,
      assetExporter: {
        getTabs: `${h("assetexporter")}/${apiVersion}/asset-exporter/tabs`,
        getTabData: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/tab-data`,
        updateTabOrder: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/tab-order`,
        getOfferExists: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/offer-exists`,
        createUpdateFeedOffer: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/offer`,
        deleteCronFeedUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/cronfeed`,
        rawFeedsUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/raw-feeds`,
        fetchFeedCrons: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/feedCrons`,
        validateCredentials: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/validateCredentials`,
        smartColumnUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/smart-columns`,
        deleteSmartCol: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/feeds`,
        csvDownloadUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/csv-download-url`,
        processedRecordUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/processed-records`,
        processFeedsUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/feeds/process-feeds`,
        patchFeedsUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/patch-feed`,

        composeBatchOrderUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/compose-batch`,

        generateProcessedFeedCsvUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/process-csv`,

        pullLatestFeedDataUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/pull-latest-feed-data`,

        csvSignedUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/signed-url`,
        getFeedPreview: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/feed-preview`,
        putServerConfig: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/server-config`,
        getFeedsWithRules: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/feeds-with-rules`,
        processPreview: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/process-preview`,
        createFeedCron: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/create-feed-cron`,
        getFeedConfig: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/feed-config`,
        getFeedProcessStatus: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/feed-export-status`,
        getPrcdFeedForAdLib: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/processed-feed-for-adlib`,
        getGoogleSheets: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/google-sheets`,
        updateRawOfferByIdUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/raw-offer-data`,
        assetBatch: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/asset-batches`,
        exportAssetBatches: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/export-asset-batches`,
        validateFeedColumnValues: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/validate-feed-column-values`,
        validatePortalExportMetadata: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/validate-portal-export-metadata`,
        getExportAssetBatchesStatus: `${h(
          "assetexporter",
        )}/${apiVersion}/asset-exporter/export-asset-batches-status`,
        getValuesByColumnUrl: parameters =>
          returnUrlWithParameters({
            baseUrl: `${h("assetexporter")}/${apiVersion}`,
            endpoint: "asset-exporter/values-by-column",
            parameters,
          }),
        getMediaColumns: `${h(
          "assetexporter",
        )}/${apiVersion}/ad-engine/media-columns`,
        getHtmlTemplatePreviewUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/ad-engine/html-template-preview`,
        checkVideoAudio: `${h(
          "assetexporter",
        )}/${apiVersion}/ad-engine/check-video-audio`,
        getGoogleDriveAPIUrl: `${h(
          "assetexporter",
        )}/${apiVersion}/ad-engine/google-drive-api-url`,
      },

      invokeLambdaUrl: `${h(
        "assetexporter",
      )}/${apiVersion}/asset-exporter/invoke-lambda`,
      jobStatusUrl: `${h(
        "assetexporter",
      )}/${apiVersion}/asset-exporter/job-status`,
      genAI: {
        imageGen: `${h("genai")}/${apiVersion}/genai/prompt-images`,
        imageResize: `${h("genai")}/${apiVersion}/cloudinary/images`,
        imageStatus: id =>
          `${h("genai")}/${apiVersion}/cloudinary/images/${id}`,
        getSession: id => `${h("genai")}/${apiVersion}/genai/sessions/${id}`,
        createSession: `${h("genai")}/${apiVersion}/genai/sessions/create`,
      },
      adLibrary: {
        adsUrl: parameters =>
          returnUrlWithParameters({
            baseUrl: `${h("adlibrary")}/${apiVersion}`,
            endpoint: "ad-library/ads",
            parameters,
          }),
        batchAdsUrl: `${h("adlibrary")}/${apiVersion}/ad-library/batch/ads`,
        adLoad: {
          getRulesPresetsUrl: parameters =>
            returnUrlWithParameters({
              baseUrl: `${h("adlibrary")}/${apiVersion}`,
              endpoint: "ad-load/rule-presets",
              parameters,
            }),
          uploadImageToFbUrl: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-load/fb-images`,
        },
        adHistory: {
          getSessionsUrl: parameters =>
            returnUrlWithParameters({
              baseUrl: `${h("adlibrary")}/${apiVersion}`,
              endpoint: "ad-history/sessions",
              parameters,
            }),
          getSessionsPageUrl: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-history/sessions-page`,
        },
        instantExperiencesUrl: `${h(
          "adlibrary",
        )}/${apiVersion}/ad-library/instant-experiences`,
        batchInstantExperiencesUrl: `${h(
          "adlibrary",
        )}/${apiVersion}/ad-library/batch/instant-experiences`,
        everythingAdsHtmlUrl: `${h(
          "adlibrary",
        )}/${apiVersion}/everything-ads-html`,
        export: {
          getUploadSignedUrl: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-library/export/getSignedUrl`,
          getGeneratedFileUrl: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-library/export/getFileUrl`,
        },
        importUrl: `${h("adlibrary")}/${apiVersion}/ad-library/import`,
        marketingMaterialsUrl: `${h(
          "salesenablement",
        )}/${apiVersion}/marketing-materials`,
        archivesUrl: `${h("salesenablement")}/${apiVersion}/archives`,
        adReview: {
          getAdPreviewDimensionsUrl: `${h(
            "campaignmgmt",
          )}/${apiVersion}/ad-review/ads/dimensions`,
          adsUrl: params =>
            returnUrlWithParameters({
              baseUrl: `${h("campaignmgmt")}/${apiVersion}`,
              endpoint: "ad-review/ads",
              parameters: params,
            }),
          sharedAdReviewsUrl: (
            sharedAdReviewId?: string,
            params?: IGetSharedAdReviewParams,
          ) => {
            const pathParameters = sharedAdReviewId
              ? `/${sharedAdReviewId}`
              : "";

            return returnUrlWithParameters({
              baseUrl: `${h("campaignmgmt")}/${apiVersion}`,
              endpoint: `ad-review/shared-ad-reviews${pathParameters}`,
              parameters: params,
            });
          },
          facebookInstantExperienceUrl: (
            adId: string,
            params?: IGetFacebookIEParams,
          ) => {
            return returnUrlWithParameters({
              baseUrl: `${h("campaignmgmt")}/${apiVersion}`,
              endpoint: `ad-review/facebook-instant-experience/${adId}`,
              parameters: params,
            });
          },
          flipFacebookCampaignsUrl: () => {
            return returnUrlWithParameters({
              baseUrl: `${h("campaignmgmt")}/${apiVersion}`,
              endpoint: `ad-review/flip-facebook-campaigns`,
            });
          },
          getFlipStatusUrl: (executionId: string) => {
            return returnUrlWithParameters({
              baseUrl: `${h("campaignmgmt")}/${apiVersion}`,
              endpoint: `ad-review/flip-facebook-campaigns/${executionId}`,
            });
          },
        },
        youtube: {
          uploadVideos: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-library/youtube/upload-videos`,
          getChannels: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-library/youtube/channels`,
          getAccounts: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-library/youtube/accounts`,
        },
        pmax: {
          internal: {
            assetGroups: `${h(
              "adlibrary",
            )}/${apiVersion}/ad-library/pmax/internal/asset-groups`,
            campaigns: `${h(
              "adlibrary",
            )}/${apiVersion}/ad-library/pmax/internal/campaigns`,
            config: {
              urlExclusions: `${h(
                "adlibrary",
              )}/${apiVersion}/ad-library/pmax/internal/config/url-exclusions`,
            },
            assetGroupStatus: `${h(
              "adlibrary",
            )}/${apiVersion}/ad-library/pmax/internal/asset-group-status`,
          },
          getAudienceSignals: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-library/pmax/audience-signals`,
          getAdAccounts: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-library/pmax/ad-accounts`,
          getGeoTargets: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-library/pmax/geotargets`,
          getCampaigns: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-library/pmax/campaigns`,
          uploadAssetGroup: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-library/pmax/upload-asset-group`,
          load: `${h("adlibrary")}/${apiVersion}/ad-library/pmax/load`,
          assetGroups: `${h(
            "adlibrary",
          )}/${apiVersion}/ad-library/pmax/asset-groups`,
        },
      },
      pdfGenerator: {
        generatePdfExecutionsUrl: `${h(
          "pdfgenerator",
        )}/${apiVersion}/ad-review/executions`,
      },
      adLoad: {
        executionsUrl: `${h("adload")}/${apiVersion}/executions`,
        sessionsUrl: `${h("adload")}/${apiVersion}/sessions`,
      },
      // This endpoint should eventually move to the core package
      commentsUrl: params =>
        returnUrlWithParameters({
          baseUrl: `${h("campaignmgmt")}/${apiVersion}`,
          endpoint: "comments",
          parameters: params,
        }),
      qcHistoryLogsUrl: params =>
        returnUrlWithParameters({
          baseUrl: `${h("campaignmgmt")}/${apiVersion}`,
          endpoint: "ad-review/qc-history-logs",
          parameters: params,
        }),
      batchQCHistoryLogs: () =>
        returnUrlWithParameters({
          baseUrl: `${h("campaignmgmt")}/${apiVersion}`,
          endpoint: "ad-review/batch/qc-history-logs",
        }),
      // This endpoint should eventually move to the core package
      labelsUrl: `${h("adlibrary")}/${apiVersion}/labels`,
      platformProxyUrl: `${h("adlibrary")}/${apiVersion}/proxy-api`,
      getS3ObjectVersionsUrl: (parameters?: IGetS3ObjectVersionsQueryParams) =>
        returnUrlWithParameters({
          baseUrl: `${h("adlibrary")}/${apiVersion}`,
          endpoint: "object-versions",
          parameters,
        }),
      getVersionOfJsonFromS3Url: (
        parameters?: IGetVersionOfJsonFromS3QueryParams,
      ) =>
        returnUrlWithParameters({
          baseUrl: `${h("adlibrary")}/${apiVersion}`,
          endpoint: "json-version",
          parameters,
        }),
      getScreenshotUrl: query => {
        return `${h("assetgenerator")}/${apiVersion}/screenshot${
          query ? `?${query}` : ""
        }`;
      },
      getResizedImgUrl: (parameters?: IGetResizedImgQueryParams) =>
        returnUrlWithParameters({
          baseUrl: `${h("assetgenerator")}/${apiVersion}`,
          endpoint: "img-resize",
          parameters,
        }),
      getClientVersionUrl: "/.version", // .version is a file generated by the build process, located in the root of the project

      platformManagement: {
        createPlatformToken: () => {
          return `${h("adlibrary")}/${apiVersion}/platform-token`;
        },
        deletePlatformToken: platform => {
          return `${h("adlibrary")}/${apiVersion}/platform-token/${platform}`;
        },
        getPlatformToken: platform => {
          return `${h("adlibrary")}/${apiVersion}/platform-token/${platform}`;
        },
        getGoogleOAuthConsentUrl: () => {
          return `${h(
            "adlibrary",
          )}/${apiVersion}/platform-token/signed-google-oauth-consent-url`;
        },
        getYoutubeOAuthConsentUrl: (oem, moduleUrl) => {
          return `${h(
            "adlibrary",
          )}/${apiVersion}/platform-token/signed-youtube-oauth-consent-url?oem=${encodeURI(
            oem,
          )}&moduleUrl=${encodeURI(moduleUrl)}`;
        },
      },
      audienceManager: {
        audienceIntegrationLogs: (
          parameters?: GetAudienceIntegrationLogsParameters,
        ) =>
          returnUrlWithParameters({
            baseUrl: `${h("adlibrary")}/${apiVersion}`,
            endpoint: "audience-integration-logs",
            parameters,
          }),
      },
      campaignPlanner: {
        createCampaignPlannerUrl: `${h(
          "adlibrary",
        )}/${apiVersion}/campaign-planner`,
        getCampaignPlannersUrl: `${h(
          "adlibrary",
        )}/${apiVersion}/campaign-planner`,
        editCampaignPlannerUrl: `${h(
          "adlibrary",
        )}/${apiVersion}/campaign-planner`,
        deleteCampaignPlannerUrl: `${h(
          "adlibrary",
        )}/${apiVersion}/campaign-planner`,
        updateCampaignPlannerAdsUrl: `${h(
          "adlibrary",
        )}/${apiVersion}/campaign-planner`,
        createCampaignPlannerInstanceUrl: plannerId =>
          `${h(
            "adlibrary",
          )}/${apiVersion}/campaign-planner/${plannerId}/instances`,
        deleteCampaignPlannerInstancesUrl: `${h(
          "adlibrary",
        )}/${apiVersion}/campaign-planner/instances`,
        updateCampaignPlannerInstanceUrl: (plannerId, instanceId) =>
          `${h(
            "adlibrary",
          )}/${apiVersion}/campaign-planner/${plannerId}/instance/${instanceId}`,
        updateModifiedAdsPlannersUrl: `${h(
          "adlibrary",
        )}/${apiVersion}/campaign-planner/modified-ads`,
        updatePlannerInstanceAdsUrl: ({
          plannerId,
          instanceId,
          adId,
        }: UpdatePlannerInstanceAdParams) =>
          `${h(
            "adlibrary",
          )}/${apiVersion}/campaign-planner/${plannerId}/instance/${instanceId}/ad/${adId}`,
      },

      beeswaxCampaigns: {
        getBeeswaxCampaigns: `${h(
          "campaignmgmt",
        )}/${apiVersion}/beeswax/campaigns`,
        getBeeswaxCampaign: id =>
          `${h("campaignmgmt")}/${apiVersion}/beeswax/campaigns/${id}`,
        createBeeswaxCampaign: `${h(
          "campaignmgmt",
        )}/${apiVersion}/beeswax/campaigns`,
        updateBeeswaxCampaign: id =>
          `${h("campaignmgmt")}/${apiVersion}/beeswax/campaigns/${id}`,
        deleteBeeswaxCampaigns: `${h(
          "campaignmgmt",
        )}/${apiVersion}/beeswax/campaigns`,
        getBeeswaxAdvertisers: `${h(
          "campaignmgmt",
        )}/${apiVersion}/beeswax/advertisers`,
        loadBeeswaxCampaigns: `${h(
          "campaignmgmt",
        )}/${apiVersion}/beeswax/load/campaigns`,
        getBeeswaxCampaignsLoadingStatus: id =>
          `${h(
            "campaignmgmt",
          )}/${apiVersion}/beeswax/load/campaigns/${id}/status`,
      },

      media: {
        token: `${h("media")}/${apiVersion}/media/token`,
      },
      salesEnablement: {
        templatesUrl: `
          ${h("salesenablement")}/${apiVersion}/templates`,
        presignedUrl: `${h(
          "salesenablement",
        )}/${apiVersion}/templates/presigned-url`,
        parseIdmlUrl: `${h(
          "salesenablement",
        )}/${apiVersion}/templates/parse-idml`,
        renderIdmlUrl: `${h(
          "salesenablement",
        )}/${apiVersion}/templates/render-idml`,
        parseHtmlUrl: `${h(
          "salesenablement",
        )}/${apiVersion}/templates/parse-html`,
        productsUrl: `${h("salesenablement")}/${apiVersion}/products`,
        disclosuresUrl: `${h("salesenablement")}/${apiVersion}/disclosures`,
        accountsUrl: `${h("salesenablement")}/${apiVersion}/accounts`,

        // marketingMaterials
        marketingMaterialsUrl: `${h(
          "salesenablement",
        )}/${apiVersion}/marketing-materials`,
        getFileInfoUrl: `${h(
          "salesenablement",
        )}/${apiVersion}/templates/file-info`,
        agentFeedInfoUrl: `${h("salesenablement")}/${apiVersion}/agent-info`,
        renderAsset: `${h(
          "salesenablement",
        )}/${apiVersion}/templates/render-asset`,
        agentProfilesUrl: `${h(
          "salesenablement",
        )}/${apiVersion}/agent-profiles`,
        archivesUrl: `${h("salesenablement")}/${apiVersion}/archives`,
        processPdfTemplateUrl: `${h(
          "salesenablement",
        )}/${apiVersion}/templates/process-pdf-template`,
      },
      brochure: {
        brochureRequestsUrl: `${h("brochure", "brochure")}/brochure/requests`,
        triggerBrochureUrl: `${h("brochure", "brochure")}/brochure/trigger`,
      },
      base: {
        upserUserAttrs: `${h(
          "base",
        )}/${apiVersion}/base/users/upsert-user-attrs`,
      },
    },
    s3AssetBucket: `${process.env.REACT_APP_S3_UPLOAD_ASSETS}`,
  };

  return config;
};

export default configGenerator;
